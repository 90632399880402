.radius img{
    border-radius: 15px;
}

.justifytext{
    text-align: justify;
    text-justify: inter-word;
}

.aboutcolor{
    background-color: #8b5621; 
  border: none;
  color: white;
  border-radius: 5px;
  padding: 5px 17px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.businesstraIningconsultancyberhampore {
 
    background: white;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  .businesstraIningconsultancyberhampore img {
    width: 100%;
    height: 60%;
    object-fit: cover;
  }
  .businesstraIningconsultancyberhampore .content {
    padding: 15px;
    text-align: center;
  }
  .businesstraIningconsultancyberhampore:hover {
    transform: scale(1.06);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }








  * {box-sizing: border-box}

/* Container needed to position the overlay. Adjust the width as needed */
.Businesstrainerberhampore {
  position: relative;

}

/* Make the image to responsive */
.image9 {
  display: block;
  width: 100%;
  height: auto;
}

/* The overlay effect - lays on top of the container and over the image */
.businessconsultancywb {
  position: absolute;
  bottom: 0;
  background: rgb(162, 194, 240);
  background: rgba(23, 23, 23, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
  border-radius: 1px;
}

/* When you mouse over the container, fade in the overlay title */
.Businesstrainerberhampore:hover .businessconsultancywb {
  opacity: 1;
}

.colorbackground{
background-color: #3f7db0;
color: white;
}

.back{
    background-color: #f2f8fd;
}








.hcolor{
  color: #3f7db0;
}






.enquiry{
  background-color: #8b5621; 
  border: none;
  color: white;
  border-radius: 5px;
  padding: 5px 17px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;

}



.berhamporeconsultancy {
 
  background: white;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  transition: transform 0.3s,  0.3s;
}


.berhamporeconsultancy:hover {
  transform: scale(1.06);
  
}


.backgroundcolor{
  background-color: #fcfeff;
}

.justify{
  text-align: justify;
  text-justify: inter-word;
}


.btn-grad12 {
  background-image: linear-gradient(to right, #4f0321 0%, #0a6b65  51%, #0a4c78  100%);
  margin: 0px;
  padding: 13px 29px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 3px;
  display: block;
  font-size: 24px;
}

.btn-grad12:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}


.btn-grad13 {
  background-image: linear-gradient(to right, #1257be 0%, #10345a  51%, #710101  100%);
  margin: 0px;
  padding: 13px 29px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 3px;
  display: block;
  font-size: 24px;
}

.btn-grad13:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.buttonlearn{
  background-color: #ffffff; 
  border: none;
  color: rgb(5, 20, 113);
  border-radius: 5px;
  padding: 7px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 32px;
}
.radiusnew img {
    border-radius: 8px;
}

.colornav a{
    color: rgb(18, 94, 160);
}

.btnnav{
    background-color: #3d6c79; 
    border: none;
    color: white;
    border-radius: 5px;
    padding: 6px 22px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }
